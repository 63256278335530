/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfilePublic
 */
export interface UserProfilePublic {
    /**
     * 
     * @type {string}
     * @memberof UserProfilePublic
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePublic
     */
    biography?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePublic
     */
    readonly avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfilePublic
     */
    readonly has_avatar: boolean;
}

/**
 * Check if a given object implements the UserProfilePublic interface.
 */
export function instanceOfUserProfilePublic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatar_url" in value;
    isInstance = isInstance && "has_avatar" in value;

    return isInstance;
}

export function UserProfilePublicFromJSON(json: any): UserProfilePublic {
    return UserProfilePublicFromJSONTyped(json, false);
}

export function UserProfilePublicFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfilePublic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'school': !exists(json, 'school') ? undefined : json['school'],
        'biography': !exists(json, 'biography') ? undefined : json['biography'],
        'avatar_url': json['avatar_url'],
        'has_avatar': json['has_avatar'],
    };
}

export function UserProfilePublicToJSON(value?: UserProfilePublic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'school': value.school,
        'biography': value.biography,
    };
}

