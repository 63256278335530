/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Status526Enum } from './Status526Enum';
import {
    Status526EnumFromJSON,
    Status526EnumFromJSONTyped,
    Status526EnumToJSON,
} from './Status526Enum';
import type { TeamProfilePublic } from './TeamProfilePublic';
import {
    TeamProfilePublicFromJSON,
    TeamProfilePublicFromJSONTyped,
    TeamProfilePublicToJSON,
} from './TeamProfilePublic';
import type { UserPublic } from './UserPublic';
import {
    UserPublicFromJSON,
    UserPublicFromJSONTyped,
    UserPublicToJSON,
} from './UserPublic';

/**
 * 
 * @export
 * @interface TeamPublic
 */
export interface TeamPublic {
    /**
     * 
     * @type {number}
     * @memberof TeamPublic
     */
    readonly id: number;
    /**
     * 
     * @type {TeamProfilePublic}
     * @memberof TeamPublic
     */
    profile?: TeamProfilePublic;
    /**
     * 
     * @type {string}
     * @memberof TeamPublic
     */
    readonly episode: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPublic
     */
    name: string;
    /**
     * 
     * @type {Array<UserPublic>}
     * @memberof TeamPublic
     */
    readonly members: Array<UserPublic>;
    /**
     * 
     * @type {Status526Enum}
     * @memberof TeamPublic
     */
    readonly status: Status526Enum;
    /**
     * 
     * @type {string}
     * @memberof TeamPublic
     */
    readonly has_active_submission: string;
}

/**
 * Check if a given object implements the TeamPublic interface.
 */
export function instanceOfTeamPublic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "episode" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "members" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "has_active_submission" in value;

    return isInstance;
}

export function TeamPublicFromJSON(json: any): TeamPublic {
    return TeamPublicFromJSONTyped(json, false);
}

export function TeamPublicFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamPublic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'profile': !exists(json, 'profile') ? undefined : TeamProfilePublicFromJSON(json['profile']),
        'episode': json['episode'],
        'name': json['name'],
        'members': ((json['members'] as Array<any>).map(UserPublicFromJSON)),
        'status': Status526EnumFromJSON(json['status']),
        'has_active_submission': json['has_active_submission'],
    };
}

export function TeamPublicToJSON(value?: TeamPublic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': TeamProfilePublicToJSON(value.profile),
        'name': value.name,
    };
}

