/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Status526Enum } from './Status526Enum';
import {
    Status526EnumFromJSON,
    Status526EnumFromJSONTyped,
    Status526EnumToJSON,
} from './Status526Enum';
import type { TeamProfilePrivate } from './TeamProfilePrivate';
import {
    TeamProfilePrivateFromJSON,
    TeamProfilePrivateFromJSONTyped,
    TeamProfilePrivateToJSON,
} from './TeamProfilePrivate';
import type { UserPublic } from './UserPublic';
import {
    UserPublicFromJSON,
    UserPublicFromJSONTyped,
    UserPublicToJSON,
} from './UserPublic';

/**
 * 
 * @export
 * @interface TeamPrivate
 */
export interface TeamPrivate {
    /**
     * 
     * @type {number}
     * @memberof TeamPrivate
     */
    readonly id: number;
    /**
     * 
     * @type {TeamProfilePrivate}
     * @memberof TeamPrivate
     */
    profile?: TeamProfilePrivate;
    /**
     * 
     * @type {string}
     * @memberof TeamPrivate
     */
    readonly episode: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPrivate
     */
    readonly name: string;
    /**
     * 
     * @type {Array<UserPublic>}
     * @memberof TeamPrivate
     */
    readonly members: Array<UserPublic>;
    /**
     * 
     * @type {string}
     * @memberof TeamPrivate
     */
    readonly join_key: string;
    /**
     * 
     * @type {Status526Enum}
     * @memberof TeamPrivate
     */
    readonly status: Status526Enum;
}

/**
 * Check if a given object implements the TeamPrivate interface.
 */
export function instanceOfTeamPrivate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "episode" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "members" in value;
    isInstance = isInstance && "join_key" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TeamPrivateFromJSON(json: any): TeamPrivate {
    return TeamPrivateFromJSONTyped(json, false);
}

export function TeamPrivateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamPrivate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'profile': !exists(json, 'profile') ? undefined : TeamProfilePrivateFromJSON(json['profile']),
        'episode': json['episode'],
        'name': json['name'],
        'members': ((json['members'] as Array<any>).map(UserPublicFromJSON)),
        'join_key': json['join_key'],
        'status': Status526EnumFromJSON(json['status']),
    };
}

export function TeamPrivateToJSON(value?: TeamPrivate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': TeamProfilePrivateToJSON(value.profile),
    };
}

