/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScrimmageRecord
 */
export interface ScrimmageRecord {
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecord
     */
    team_id: number;
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecord
     */
    wins: number;
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecord
     */
    losses: number;
    /**
     * 
     * @type {number}
     * @memberof ScrimmageRecord
     */
    ties: number;
}

/**
 * Check if a given object implements the ScrimmageRecord interface.
 */
export function instanceOfScrimmageRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "team_id" in value;
    isInstance = isInstance && "wins" in value;
    isInstance = isInstance && "losses" in value;
    isInstance = isInstance && "ties" in value;

    return isInstance;
}

export function ScrimmageRecordFromJSON(json: any): ScrimmageRecord {
    return ScrimmageRecordFromJSONTyped(json, false);
}

export function ScrimmageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrimmageRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team_id': json['team_id'],
        'wins': json['wins'],
        'losses': json['losses'],
        'ties': json['ties'],
    };
}

export function ScrimmageRecordToJSON(value?: ScrimmageRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team_id': value.team_id,
        'wins': value.wins,
        'losses': value.losses,
        'ties': value.ties,
    };
}

