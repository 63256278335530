/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GenderEnum {
    F = 'F',
    M = 'M',
    N = 'N',
    Star = '*',
    QuestionMark = '?'
}


export function GenderEnumFromJSON(json: any): GenderEnum {
    return GenderEnumFromJSONTyped(json, false);
}

export function GenderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderEnum {
    return json as GenderEnum;
}

export function GenderEnumToJSON(value?: GenderEnum | null): any {
    return value as any;
}

