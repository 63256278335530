/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatusBccEnum } from './StatusBccEnum';
import {
    StatusBccEnumFromJSON,
    StatusBccEnumFromJSONTyped,
    StatusBccEnumToJSON,
} from './StatusBccEnum';

/**
 * 
 * @export
 * @interface TournamentSubmission
 */
export interface TournamentSubmission {
    /**
     * 
     * @type {number}
     * @memberof TournamentSubmission
     */
    readonly id: number;
    /**
     * 
     * @type {StatusBccEnum}
     * @memberof TournamentSubmission
     */
    readonly status: StatusBccEnum;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly logs: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly episode: string;
    /**
     * 
     * @type {number}
     * @memberof TournamentSubmission
     */
    readonly team: number;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly teamname: string;
    /**
     * 
     * @type {number}
     * @memberof TournamentSubmission
     */
    readonly user: number;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly username: string;
    /**
     * 
     * @type {Date}
     * @memberof TournamentSubmission
     */
    readonly created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentSubmission
     */
    readonly accepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly _package: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly description: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentSubmission
     */
    readonly tournament: string;
}

/**
 * Check if a given object implements the TournamentSubmission interface.
 */
export function instanceOfTournamentSubmission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "logs" in value;
    isInstance = isInstance && "episode" in value;
    isInstance = isInstance && "team" in value;
    isInstance = isInstance && "teamname" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "accepted" in value;
    isInstance = isInstance && "_package" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "tournament" in value;

    return isInstance;
}

export function TournamentSubmissionFromJSON(json: any): TournamentSubmission {
    return TournamentSubmissionFromJSONTyped(json, false);
}

export function TournamentSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': StatusBccEnumFromJSON(json['status']),
        'logs': json['logs'],
        'episode': json['episode'],
        'team': json['team'],
        'teamname': json['teamname'],
        'user': json['user'],
        'username': json['username'],
        'created': (new Date(json['created'])),
        'accepted': json['accepted'],
        '_package': json['package'],
        'description': json['description'],
        'tournament': json['tournament'],
    };
}

export function TournamentSubmissionToJSON(value?: TournamentSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

