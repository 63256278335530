/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StyleEnum } from './StyleEnum';
import {
    StyleEnumFromJSON,
    StyleEnumFromJSONTyped,
    StyleEnumToJSON,
} from './StyleEnum';

/**
 * 
 * @export
 * @interface Tournament
 */
export interface Tournament {
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    name_short: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    name_long: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    blurb?: string;
    /**
     * 
     * @type {string}
     * @memberof Tournament
     */
    episode: string;
    /**
     * 
     * @type {StyleEnum}
     * @memberof Tournament
     */
    style: StyleEnum;
    /**
     * 
     * @type {Date}
     * @memberof Tournament
     */
    display_date: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tournament
     */
    eligibility_includes?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tournament
     */
    eligibility_excludes?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Tournament
     */
    require_resume: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tournament
     */
    is_public: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Tournament
     */
    submission_freeze: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tournament
     */
    submission_unfreeze: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Tournament
     */
    readonly is_eligible: boolean;
}

/**
 * Check if a given object implements the Tournament interface.
 */
export function instanceOfTournament(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name_short" in value;
    isInstance = isInstance && "name_long" in value;
    isInstance = isInstance && "episode" in value;
    isInstance = isInstance && "style" in value;
    isInstance = isInstance && "display_date" in value;
    isInstance = isInstance && "require_resume" in value;
    isInstance = isInstance && "is_public" in value;
    isInstance = isInstance && "submission_freeze" in value;
    isInstance = isInstance && "submission_unfreeze" in value;
    isInstance = isInstance && "is_eligible" in value;

    return isInstance;
}

export function TournamentFromJSON(json: any): Tournament {
    return TournamentFromJSONTyped(json, false);
}

export function TournamentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tournament {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name_short': json['name_short'],
        'name_long': json['name_long'],
        'blurb': !exists(json, 'blurb') ? undefined : json['blurb'],
        'episode': json['episode'],
        'style': StyleEnumFromJSON(json['style']),
        'display_date': (new Date(json['display_date'])),
        'eligibility_includes': !exists(json, 'eligibility_includes') ? undefined : json['eligibility_includes'],
        'eligibility_excludes': !exists(json, 'eligibility_excludes') ? undefined : json['eligibility_excludes'],
        'require_resume': json['require_resume'],
        'is_public': json['is_public'],
        'submission_freeze': (new Date(json['submission_freeze'])),
        'submission_unfreeze': (new Date(json['submission_unfreeze'])),
        'is_eligible': json['is_eligible'],
    };
}

export function TournamentToJSON(value?: Tournament | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name_short': value.name_short,
        'name_long': value.name_long,
        'blurb': value.blurb,
        'episode': value.episode,
        'style': StyleEnumToJSON(value.style),
        'display_date': (value.display_date.toISOString().substr(0,10)),
        'eligibility_includes': value.eligibility_includes,
        'eligibility_excludes': value.eligibility_excludes,
        'require_resume': value.require_resume,
        'is_public': value.is_public,
        'submission_freeze': (value.submission_freeze.toISOString()),
        'submission_unfreeze': (value.submission_unfreeze.toISOString()),
    };
}

