/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoscrimRequest
 */
export interface AutoscrimRequest {
    /**
     * 
     * @type {number}
     * @memberof AutoscrimRequest
     */
    best_of: number;
}

/**
 * Check if a given object implements the AutoscrimRequest interface.
 */
export function instanceOfAutoscrimRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "best_of" in value;

    return isInstance;
}

export function AutoscrimRequestFromJSON(json: any): AutoscrimRequest {
    return AutoscrimRequestFromJSONTyped(json, false);
}

export function AutoscrimRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoscrimRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'best_of': json['best_of'],
    };
}

export function AutoscrimRequestToJSON(value?: AutoscrimRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'best_of': value.best_of,
    };
}

