/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeamProfilePrivateRequest } from './TeamProfilePrivateRequest';
import {
    TeamProfilePrivateRequestFromJSON,
    TeamProfilePrivateRequestFromJSONTyped,
    TeamProfilePrivateRequestToJSON,
} from './TeamProfilePrivateRequest';

/**
 * 
 * @export
 * @interface TeamCreateRequest
 */
export interface TeamCreateRequest {
    /**
     * 
     * @type {TeamProfilePrivateRequest}
     * @memberof TeamCreateRequest
     */
    profile?: TeamProfilePrivateRequest;
    /**
     * 
     * @type {string}
     * @memberof TeamCreateRequest
     */
    episode?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamCreateRequest
     */
    name: string;
}

/**
 * Check if a given object implements the TeamCreateRequest interface.
 */
export function instanceOfTeamCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function TeamCreateRequestFromJSON(json: any): TeamCreateRequest {
    return TeamCreateRequestFromJSONTyped(json, false);
}

export function TeamCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile': !exists(json, 'profile') ? undefined : TeamProfilePrivateRequestFromJSON(json['profile']),
        'episode': !exists(json, 'episode') ? undefined : json['episode'],
        'name': json['name'],
    };
}

export function TeamCreateRequestToJSON(value?: TeamCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': TeamProfilePrivateRequestToJSON(value.profile),
        'episode': value.episode,
        'name': value.name,
    };
}

