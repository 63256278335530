/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryEnum } from './CountryEnum';
import {
    CountryEnumFromJSON,
    CountryEnumFromJSONTyped,
    CountryEnumToJSON,
} from './CountryEnum';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
} from './GenderEnum';

/**
 * 
 * @export
 * @interface UserProfilePrivateRequest
 */
export interface UserProfilePrivateRequest {
    /**
     * 
     * @type {GenderEnum}
     * @memberof UserProfilePrivateRequest
     */
    gender: GenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePrivateRequest
     */
    gender_details?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePrivateRequest
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePrivateRequest
     */
    biography?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePrivateRequest
     */
    kerberos?: string;
    /**
     * 
     * @type {CountryEnum}
     * @memberof UserProfilePrivateRequest
     */
    country: CountryEnum;
}

/**
 * Check if a given object implements the UserProfilePrivateRequest interface.
 */
export function instanceOfUserProfilePrivateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function UserProfilePrivateRequestFromJSON(json: any): UserProfilePrivateRequest {
    return UserProfilePrivateRequestFromJSONTyped(json, false);
}

export function UserProfilePrivateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfilePrivateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gender': GenderEnumFromJSON(json['gender']),
        'gender_details': !exists(json, 'gender_details') ? undefined : json['gender_details'],
        'school': !exists(json, 'school') ? undefined : json['school'],
        'biography': !exists(json, 'biography') ? undefined : json['biography'],
        'kerberos': !exists(json, 'kerberos') ? undefined : json['kerberos'],
        'country': CountryEnumFromJSON(json['country']),
    };
}

export function UserProfilePrivateRequestToJSON(value?: UserProfilePrivateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gender': GenderEnumToJSON(value.gender),
        'gender_details': value.gender_details,
        'school': value.school,
        'biography': value.biography,
        'kerberos': value.kerberos,
        'country': CountryEnumToJSON(value.country),
    };
}

