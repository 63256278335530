/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatusBccEnum } from './StatusBccEnum';
import {
    StatusBccEnumFromJSON,
    StatusBccEnumFromJSONTyped,
    StatusBccEnumToJSON,
} from './StatusBccEnum';

/**
 * 
 * @export
 * @interface SaturnInvocationRequest
 */
export interface SaturnInvocationRequest {
    /**
     * 
     * @type {StatusBccEnum}
     * @memberof SaturnInvocationRequest
     */
    status: StatusBccEnum;
    /**
     * 
     * @type {string}
     * @memberof SaturnInvocationRequest
     */
    logs?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaturnInvocationRequest
     */
    interrupted?: boolean;
}

/**
 * Check if a given object implements the SaturnInvocationRequest interface.
 */
export function instanceOfSaturnInvocationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function SaturnInvocationRequestFromJSON(json: any): SaturnInvocationRequest {
    return SaturnInvocationRequestFromJSONTyped(json, false);
}

export function SaturnInvocationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaturnInvocationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': StatusBccEnumFromJSON(json['status']),
        'logs': !exists(json, 'logs') ? undefined : json['logs'],
        'interrupted': !exists(json, 'interrupted') ? undefined : json['interrupted'],
    };
}

export function SaturnInvocationRequestToJSON(value?: SaturnInvocationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': StatusBccEnumToJSON(value.status),
        'logs': value.logs,
        'interrupted': value.interrupted,
    };
}

