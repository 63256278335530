/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeamRating } from './TeamRating';
import {
    TeamRatingFromJSON,
    TeamRatingFromJSONTyped,
    TeamRatingToJSON,
} from './TeamRating';

/**
 * 
 * @export
 * @interface HistoricalRating
 */
export interface HistoricalRating {
    /**
     * 
     * @type {number}
     * @memberof HistoricalRating
     */
    team_id: number;
    /**
     * 
     * @type {TeamRating}
     * @memberof HistoricalRating
     */
    team_rating?: TeamRating;
}

/**
 * Check if a given object implements the HistoricalRating interface.
 */
export function instanceOfHistoricalRating(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "team_id" in value;

    return isInstance;
}

export function HistoricalRatingFromJSON(json: any): HistoricalRating {
    return HistoricalRatingFromJSONTyped(json, false);
}

export function HistoricalRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricalRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team_id': json['team_id'],
        'team_rating': !exists(json, 'team_rating') ? undefined : TeamRatingFromJSON(json['team_rating']),
    };
}

export function HistoricalRatingToJSON(value?: HistoricalRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team_id': value.team_id,
        'team_rating': TeamRatingToJSON(value.team_rating),
    };
}

