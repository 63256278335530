/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatusBccEnum {
    New = 'NEW',
    Que = 'QUE',
    Run = 'RUN',
    Try = 'TRY',
    Ok = 'OK!',
    Err = 'ERR',
    Can = 'CAN'
}


export function StatusBccEnumFromJSON(json: any): StatusBccEnum {
    return StatusBccEnumFromJSONTyped(json, false);
}

export function StatusBccEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusBccEnum {
    return json as StatusBccEnum;
}

export function StatusBccEnumToJSON(value?: StatusBccEnum | null): any {
    return value as any;
}

