/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassRequirement
 */
export interface ClassRequirement {
    /**
     * 
     * @type {string}
     * @memberof ClassRequirement
     */
    episode: string;
    /**
     * 
     * @type {number}
     * @memberof ClassRequirement
     */
    reference_player: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClassRequirement
     */
    maps: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ClassRequirement
     */
    min_score: number;
}

/**
 * Check if a given object implements the ClassRequirement interface.
 */
export function instanceOfClassRequirement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "episode" in value;
    isInstance = isInstance && "reference_player" in value;
    isInstance = isInstance && "maps" in value;
    isInstance = isInstance && "min_score" in value;

    return isInstance;
}

export function ClassRequirementFromJSON(json: any): ClassRequirement {
    return ClassRequirementFromJSONTyped(json, false);
}

export function ClassRequirementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassRequirement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'episode': json['episode'],
        'reference_player': json['reference_player'],
        'maps': json['maps'],
        'min_score': json['min_score'],
    };
}

export function ClassRequirementToJSON(value?: ClassRequirement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'episode': value.episode,
        'reference_player': value.reference_player,
        'maps': value.maps,
        'min_score': value.min_score,
    };
}

